import React, { useContext } from "react";
import { DropdownContext } from "../../context/DropdownContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/HagnosTech .jpg";
import Facebook from "../../assets/FooterLogos/Facebook.svg";
import Instagram from "../../assets/FooterLogos/Instagram.svg";
import LinkedIn from "../../assets/FooterLogos/LinkedIn.svg";
import Twitter from "../../assets/FooterLogos/Twitter.svg";
import Youtube from "../../assets/FooterLogos/Youtube.svg";

import Location from "../../assets/FooterLogos/Location.png"
import Mail from "../../assets/FooterLogos/Mail.png"
import Phone from "../../assets/FooterLogos/Phone.png"

const Footer = () => {
  const navigate = useNavigate();


  const { fullScreenDropdownVisible, setFullScreenDropdownVisible } = useContext(DropdownContext);

  return (
    <div className="flex flex-col pt-12 w-full bg-zinc-100 max-md:pt-24 max-md:max-w-full relative top-6">
      <div className="container">
        <div className="flex flex-wrap gap-5 justify-between  w-full max-w-[1574px] max-md:max-w-full">



          <div className="flex flex-col self-start relative bottom-4">
            <div className="flex flex-col typography-p2 leading-snug text-zinc-600">
              <img
                loading="lazy"
                src={Logo}
                className="object-contain max-w-full rounded-2xl aspect-[2.79] w-[150px] md:w-[230px] "
              />
              <div>Get Trusted Support From Anywhere</div>
            </div>
            <div className="flex gap-4 items-start self-start mt-4">
              {/* <img
                loading="lazy"
                src={Facebook}
                className="object-contain shrink-0 w-10 aspect-square"
              />
              <img
                loading="lazy"
                src={Twitter}
                className="object-contain shrink-0 w-10 aspect-square"
              />
              <img
                loading="lazy"
                src={Instagram}
                className="object-contain shrink-0 w-10 aspect-square"
              /> */}
              <a
                href="https://www.linkedin.com/company/hagnostech/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={LinkedIn}
                  className="object-contain shrink-0 w-10 aspect-square"
                  alt="LinkedIn"
                />
              </a>

              {/* <img
                loading="lazy"
                src={Youtube}
                className="object-contain shrink-0 w-10 aspect-square"
              /> */}
            </div>
          </div>

          <div className="flex flex-col leading-snug text-zinc-600">
            <div className="typography-h4 font-semibold">Quick Links</div>
            <div className="flex flex-col self-start mt-4 typography-p2">
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/");
                }}
                className="cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                Home
              </div>
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/about-us");
                }}
                className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                About Us
              </div>
              {/* <div onClick={() => {navigate("/digital-services/content-management")}} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Services</div> */}
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/contact-us");
                }}
                className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </div>
            </div>
          </div>



          <div className="flex flex-col leading-snug text-zinc-600">
            <div className="typography-h4 font-semibold">Services</div>
            <div className="flex flex-col mt-4 typography-p2">
              <div onClick={() => { setFullScreenDropdownVisible(true) }} className="cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Digital Services</div>
              <div onClick={() => { setFullScreenDropdownVisible(true) }} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Staffing Services</div>
              <div onClick={() => { setFullScreenDropdownVisible(true) }} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Consulting Services</div>
              {/* <div className="">
                <a
                  href="https://www.linkedin.com/company/hagnos-tech"
                  target="_blank"
                  className="text-decoration-none mt-2 cursor-pointer text-[#52525B] hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
                >
                  LinkedIn
                </a>
              </div>
              <div onClick={() => { navigate("/about-us") }} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Youtube</div> */}
            </div>
          </div>

          <div className="flex flex-col leading-snug text-zinc-600">
            <div className="typography-h4 font-semibold">Contact</div>
            <div className="flex flex-col mt-4 typography-p2">
              <div className="flex items-center mb-2">
                <img src={Phone} className="w-4 h-4 mr-2" alt="Phone" />
                <span>+1 (302) 603-7065</span>
              </div>

              <div className="flex items-center mb-2">
                <img src={Mail} className="w-4 h-4 mr-2" alt="Mail" />
                <span>inquiries@hagnos.tech</span>
              </div>
              <div className="flex items-center mb-2">
                <img src={Location} className="w-4 h-4 mr-2" alt="Location" />
                <span>850 New Burton Rd. Suite 201, Dover, DE 19904</span>
              </div>
            </div>
          </div>


        </div>



        <hr className="mt-4" style={{ color: 'gray' }}></hr>

        <div className=" w-full max-md:max-w-full" />
        <div className="flex overflow-hidden flex-col w-full leading-none text-center bg-zinc-100 text-slate-400 max-md:max-w-full">
          <div className="px-16 pt-12 pb-4  w-full bg-zinc-100 max-md:px-5 max-md:max-w-full text-xs lg:text-md flex justify-center ">
            <div className="bg-white py-2 w-[250px] rounded-xl" style={{ boxShadow: 'border-box' }}>
              Copyright © 2024 | All Rights Reserved{" "}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
