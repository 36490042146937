import React from "react";
import Router from "./router/Router";

import { DropdownProvider } from "./context/DropdownContext";




const App = () => {
  return (
    <>

  <DropdownProvider>
      <Router />
  </DropdownProvider>,
    </>
  );
};

export default App;
