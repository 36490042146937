import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ITServices from "../components/DigitalServices/ITServices/ITServices";
import AdminPannelLogin from "../adminPannel/Login/Login";
import AdminPannelLayout from "../adminPannel/AdminPannelLayout";
import Dashboard from "../adminPannel/Dashboard/Dashboard";
import AdminPannelCareer from "../adminPannel/Career/Career";

const ExperienceManagementAndMeasurement = lazy(() =>
  import(
    "../components/Consultation/ExperienceManagementAndMeasurement/ExperienceManagementAndMeasurement"
  )
);
const Audit = lazy(() => import("../components/Consultation/Audit/Audit"));
const Automation = lazy(() =>
  import("../components/Consultation/Automation/Automation")
);
const DevelopmentAndTesting = lazy(() =>
  import(
    "../components/Consultation/DevelopmentAndTesting/DevelopmentAndTesting"
  )
);
const OtherValueAddedServices = lazy(() =>
  import(
    "../components/Consultation/OtherValueAddedServices/OtherValueAddedServices"
  )
);

const Layout = lazy(() => import("../layout/Layout"));
const Home = lazy(() => import("../pages/Home"));
const Careers = lazy(() => import("../pages/Careers"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const TechnologyServices = lazy(() =>
  import("../pages/TechnologyServices/TechnologyServices")
);
const Blogs = lazy(() => import("../pages/Blogs"));
const Consulting = lazy(() => import("../pages/Consulting"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const DigitalServices = lazy(() =>
  import("../pages/DigitalServices/DigitalServices")
);
const NewsLetter = lazy(() => import("../pages/NewsLetter"));
const SupportServices = lazy(() =>
  import("../pages/SupportServices/SupportServices")
);
const ContentManagement = lazy(() =>
  import("../pages/DigitalServices/ContentManagement")
);
const GraphicDesign = lazy(() =>
  import("../pages/DigitalServices/GraphicDesign")
);
const DigitalMarketing = lazy(() =>
  import("../pages/DigitalServices/DigitalMarketing")
);
const WebsiteDevelopment = lazy(() =>
  import("../pages/DigitalServices/WebsiteDevelopment")
);
const ApplicationDev = lazy(() =>
  import("../pages/DigitalServices/ApplicationDevlopment")
);
const StaffingServices = lazy(() => import("../pages/StaffingServices"));
const ProcessConsulting = lazy(() =>
  import("../components/Consultation/ProcessConsulting/ProcessConsulting")
);
const MysteryShopping = lazy(() =>
  import("../components/Consultation/MysteryShopping/MysteryShopping")
);
const CustomerExperienceManagement = lazy(() =>
  import(
    "../components/Consultation/CustomerExperienceManagement/CustomerExperienceManagement"
  )
);
const EmployeeExperienceManagement = lazy(() =>
  import(
    "../components/Consultation/EmployeeExperienceManagement/EmployeeExperienceManagement"
  )
);

const JobDescription = lazy(() =>
  import("../components/Careers/JobDescription/JobDescription")
);

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Suspense>
                <Home />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout>
              <Suspense>
                <AboutUs />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/technology-services"
          element={
            <Layout>
              <Suspense>
                <TechnologyServices />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="digital-services"
          element={
            <Layout>
              <Suspense>
                <DigitalServices />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/website-development"
          element={
            <Layout>
              <Suspense>
                <WebsiteDevelopment />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/content-management"
          element={
            <Layout>
              <Suspense>
                <ContentManagement />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/application-devlopment"
          element={
            <Layout>
              <Suspense>
                <ApplicationDev />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/graphic-designing"
          element={
            <Layout>
              <Suspense>
                <GraphicDesign />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/digital-marketing"
          element={
            <Layout>
              <Suspense>
                <DigitalMarketing />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/digital-services/IT-services"
          element={
            <Layout>
              <Suspense>
                <ITServices />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="consulting-services"
          element={
            <Layout>
              <Suspense>
                <Consulting />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/experience-management-and-measurement"
          element={
            <Layout>
              <Suspense>
                <ExperienceManagementAndMeasurement />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/mystery-shopping"
          element={
            <Layout>
              <Suspense>
                <MysteryShopping />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/customer-experience-management"
          element={
            <Layout>
              <Suspense>
                <CustomerExperienceManagement />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/employee-experience-management"
          element={
            <Layout>
              <Suspense>
                <EmployeeExperienceManagement />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/audit"
          element={
            <Layout>
              <Suspense>
                <Audit />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/automation"
          element={
            <Layout>
              <Suspense>
                <Automation />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/development-and-testing"
          element={
            <Layout>
              <Suspense>
                <DevelopmentAndTesting />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/process-consulting"
          element={
            <Layout>
              <Suspense>
                <ProcessConsulting />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting-services/other-value-added-services"
          element={
            <Layout>
              <Suspense>
                <OtherValueAddedServices />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/staffing-services"
          element={
            <Layout>
              <Suspense>
                <StaffingServices />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="support-services"
          element={
            <Layout>
              <Suspense>
                <SupportServices />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/consulting"
          element={
            <Layout>
              <Suspense>
                <Consulting />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/blogs"
          element={
            <Layout>
              <Suspense>
                <Blogs />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/newsletter"
          element={
            <Layout>
              <Suspense>
                <NewsLetter />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/contact-us"
          element={
            <Layout>
              <Suspense>
                <ContactUs />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/careers"
          element={
            <Layout>
              <Suspense>
                <Careers />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/admin-pannel"
          element={
            <>
              <AdminPannelLogin />
            </>
          }
        />

        <Route
          path="/admin-pannel/dashboard"
          element={
            <>
              <AdminPannelLayout>
                <Dashboard />
              </AdminPannelLayout>
            </>
          }
        />

        <Route
          path="/admin-pannel/career"
          element={
            <>
              <AdminPannelLayout>
                <AdminPannelCareer />
              </AdminPannelLayout>
            </>
          }
        />

        <Route
          path="/job-description"
          element={
            <Layout>
              <Suspense>
                <JobDescription />
              </Suspense>
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
