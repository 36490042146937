import React, { useMemo, useState, useEffect } from "react";
import { Typography, Button, Link } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import MuiTable from "../../components/CommonComponents/MuiTable/MuiTable";

export function CandidateApplications() {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const hideColumns = ["resume"];

  useEffect(() => {
    // Fetch candidates when the component mounts
    axios
      .get("https://hagnos.tech/api/candidates")
      .then((response) => setCandidates(response.data))
      .catch((error) => console.error("Error fetching candidates:", error));
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSort = (property) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredCandidates = useMemo(() => {
    return candidates.filter((candidate) => {
      const name = candidate.name ? candidate.name.toLowerCase() : "";
      const email = candidate.email ? candidate.email.toLowerCase() : "";
      const appliedFor = candidate.appliedFor
        ? candidate.appliedFor.toLowerCase()
        : "";

      return (
        name.includes(searchTerm) ||
        email.includes(searchTerm) ||
        appliedFor.includes(searchTerm)
      );
    });
  }, [candidates, searchTerm]);

  const sortedCandidates = useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    };
    return [...filteredCandidates].sort(comparator);
  }, [filteredCandidates, order, orderBy]);

  const handleDeleteCandidate = (id) => {
    // Delete a candidate
    axios
      .delete(`https://hagnos.tech/api/candidates/delete/${id}`)
      .then(() => {
        setCandidates(candidates.filter((candidate) => candidate._id !== id));
      })
      .catch((error) => console.error("Error deleting candidate:", error));
  };

  const columns = [
    {
      accessorKey: "jobTitle",
      header: "Job Title",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone Number",
    },
    {
      accessorKey: "noticePeriod",
      header: "Notice Period",
    },
    {
      accessorKey: "resume",
      header: "Resume",
    },
    {
      accessorKey: "yearsOfExperience",
      header: "Years of Experience",
    },
    {
      accessorKey: "nationality",
      header: "Nationality",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
    },
  ];

  const customColumns = [
    {
      accessorKey: "resume",
      header: "Resume",
      Cell: ({ row }) => {
        return (
          <Link
            href={`https://hagnos.tech/${row.original.resume}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained">View</Button>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Candidate Applications
      </Typography>

      <MuiTable
        columns={columns}
        data={candidates}
        customColumns={customColumns}
        options={[]}
        loading={[]}
        column_order={[]}
        column_visibilty={[]}
        hideColumns={hideColumns}
        persistTable="remove allocation"
        info="To remove allocation of lead from a team and user, select the leads in the table and click unallocate from team/user (note: unallocating leads from the team will also automatically unallocate from the user)."
      />

      {/* <TextField
        fullWidth
        variant="outlined"
        placeholder="Search candidates..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaSearch />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="candidate applications table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={handleSort("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Resume</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "noticePeriod"}
                  direction={orderBy === "noticePeriod" ? order : "asc"}
                  onClick={handleSort("noticePeriod")}
                >
                  Notice Period
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "appliedFor"}
                  direction={orderBy === "appliedFor" ? order : "asc"}
                  onClick={handleSort("appliedFor")}
                >
                  Applied For
                </TableSortLabel>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCandidates.map((candidate) => (
              <TableRow key={candidate._id}>
                <TableCell>{candidate.name}</TableCell>
                <TableCell>{candidate.phoneNumber}</TableCell>
                <TableCell>{candidate.email}</TableCell>
                <TableCell>
                  <Link
                    href={`https://hagnos.tech/${candidate.resume}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: "none",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ minWidth: "auto", p: "4px 8px" }}
                    >
                      📄 View
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>{candidate.noticePeriod}</TableCell>
                <TableCell>{candidate.appliedFor}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleDeleteCandidate(candidate._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
}
