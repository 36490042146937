import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Chip,
  Stack
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
export function AddJobModal({ open, onClose, onJobAdded, setUpdatedJob }) {

  const [jobTitle, setJobTitle] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [shortDescription, setShortDescription] = useState(""); 
  const [chips, setChips] = useState([]); // To store chip values

  const handleAddJob = () => {
    if (jobTitle && jobLocation && jobDescription && shortDescription && chips.length > 0) {
      const newJob = {
        title: jobTitle,
        location: jobLocation,
        description: jobDescription,
        shortDescription, // added to new job object
        chips, // added to new job object
      };

      axios
        .post("https://hagnos.tech/api/job/post", newJob, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          onClose();
          onJobAdded(response.data); 
          setJobTitle("");
          setJobLocation("");
          setJobDescription("");
          setShortDescription(""); 
          setChips([])
          setUpdatedJob((prev) => prev + 1);
        })
        .catch((error) => {
          console.error("Error adding job:", error);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // Add the new chip when Enter is pressed
      setChips([...chips, e.target.value]);
      e.target.value = ""; // Clear the input field
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "600px",
          maxWidth: "900px",
        },
      }}
    >
      <DialogTitle>Add New Job</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Job Title"
          type="text"
          fullWidth
          variant="outlined"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Location"
          type="text"
          fullWidth
          variant="outlined"
          value={jobLocation}
          onChange={(e) => setJobLocation(e.target.value)}
        />        
        <TextField
          margin="dense"
          label="Chips (e.g., Fulltime)"
          type="text"
          fullWidth
          variant="outlined"
          onKeyDown={handleKeyDown} 
          placeholder="Press Enter to add"
        />
        <Stack direction="row" spacing={1} mt={2}>
          {chips.map((chip, index) => (
            <Chip
              key={index}
              label={chip}
              onDelete={() => handleDeleteChip(chip)}
              color="primary"
              className="mb-2"
            />
          ))}
        </Stack>
        <TextField
          margin="dense"
          label="Short Description"
          type="text"
          fullWidth
          variant="outlined"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          multiline
          rows={4}
        />

        <ReactQuill
          className="mt-2"
          value={jobDescription}
          onChange={setJobDescription}
          placeholder="Full Job Description"
          style={{ height: "200px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddJob}>Add Job</Button>
      </DialogActions>
    </Dialog>
  );
}
