import React, { useEffect } from "react";
import "./Modal.css"; // Add your own styles or adjust according to your design

const Modal = ({ isOpen, onClose, heading, message }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";

      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => {
        clearTimeout(timer); 
        document.body.style.overflow = ""; 
      };
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay ${isOpen ? "fade-in" : "fade-out"}`} style={{ zIndex: 40 }}>
      <div className="modal-content bg-white">
        <h2 className="typography-h2">{heading}</h2>
        <p className="typography-h5-sky">{message}</p>
      </div>
    </div>
  );
};

export default Modal;
