import { useRef, useLayoutEffect } from "react";
import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_Table,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { useCallback } from "react";
import "./MuiTable.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { exportToCSV } from "react-easy-export";
import { exportToExcel } from "react-easy-export";

import { Menu, Dropdown } from "antd";

import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
} from "material-react-table";

import { Download, Settings } from "react-feather";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { read, utils, writeFile } from "xlsx";
import { MenuItem } from "@mui/material";

import { Tooltip } from "antd";
import { Info } from "react-feather";
import { data } from "autoprefixer";

const API_PATH = process.env.REACT_APP_API_PATH



function filterColumnsAndToUppercase(data, columnsToHide) {
  return data
    .filter((column) => !columnsToHide.includes(column.accessorKey))
    .map((column) => ({
      ...column,
      header: column.header.charAt(0).toUpperCase() + column.header.slice(1),
    }));
}

const downloadExcelFile = (jsondata, filename) => {
  const headers = jsondata.length < 1 ? [] : Object.keys(jsondata[0]);

  var Heading = [[...headers]];
  var Data = jsondata;
  // var Data = [
  //   {firstName:"Jack", lastName: "Sparrow", email: "abc@example.com"},
  //   {firstName:"Harry", lastName: "Potter", email: "abc@example.com"},
  // ];

  //Had to create a new workbook and then add the header
  const ws = utils.book_new();
  const wb = utils.book_new();
  utils.sheet_add_aoa(ws, Heading);

  //Starting in the second row to avoid overriding and skipping headers
  const data = utils.sheet_add_json(ws, Data, {
    origin: "A2",
    skipHeader: true,
  });
  utils.book_append_sheet(wb, ws, "sheet1");
  writeFile(wb, filename);
}

function formatDateTime(isoDateString) {
  const date = new Date(isoDateString);

  const day = date.getDate().toString().padStart(2, "0"); // Day with leading zero
  const month = date.toLocaleString("en-US", { month: "short" }); // Short month name
  const year = date.getFullYear(); // Full year
  const time = date.toTimeString().split(" ")[0]; // Extract time (HH:mm:ss)

  return `${day}-${month}-${year}, ${time}`;
}

function updateDates(dataArray) {
  return dataArray.map((item) => {
    const updatedItem = { ...item }; // Create a copy to avoid mutating the original object
    updatedItem.createdAt = formatDateTime(item.createdAt);
    updatedItem.updatedAt = formatDateTime(item.updatedAt);
    return updatedItem;
  });
}

export default function App(props) {
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [density, setDensity] = useState("compact");
  const [rowSelection, setRowSelection] = useState({});
  const [columnPinning, setColumnPinning] = useState({});
  const [pageIndex, setpageIndex] = useState(0);
  const [pageSize, setpageSize] = useState(10);

  const paginationRef = useRef(null);
  paginationRef.current = pagination;

  let columns = props.columns
  let data = updateDates(props.data)
  let options = props.options;

  const isMounted = useRef(true);
  const isInitialRender = useRef(true);


  console.log(props.data)


  columns = props.hideColumns
    ? filterColumnsAndToUppercase(columns, props.hideColumns)
    : columns;

  if (props.customFirst) {
    columns = [...(props.customColumns ? props.customColumns : []), ...columns];
  } else {
    if (columns[0] || props.customColumnsOnly) {
      columns = [
        ...columns,
        ...(props.customColumns ? props.customColumns : []),
      ];
    }
  }


  //////// EXPORTING OPTIONS  ////////////////////////////////////////////////////

  const menu = (
    <Menu>
      <Menu.Item
        key="exportCSV"
        onClick={() => {
          exportToCSV(data, "Table.csv");
        }}
      >
        Export CSV
      </Menu.Item>
      <Menu.Item
        key="exportExcel"
        onClick={() => {
          downloadExcelFile(data, "Table.xlsx");
        }}
      >
        Export Excel
      </Menu.Item>
      {/* <Menu.Item
        key="exportPDF"
        onClick={exportPDF}
      >Export PDF
      </Menu.Item> */}
    </Menu>
  );

  ////////// SAVING COLUMN ORDERING IN BACKEND /////////////////////////////////////////////////////////////////

  let storedColumnOrder = props.column_order;

  const [newColOrder, setNewColOrder] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);

  const [flag, setFlag] = useState(0);

  const handleUpdateSeq = (columnOrder) => {
    if (props.serverSideOrdering) {
      if (flag == 0) {
        setFlag((prev) => {
          return prev + 1;
        });
        return;
      } else {

        
        setNewColOrder(columnOrder);
        if (columnOrder[0] && props.serverSideOrdering) {
          let postData = {
            swalhidden: true,
            tableName: props.persistTable,
            viewableColumn: "false",
            columnOrder: columnOrder,
          };


          // axios
          //   .post(`${API_PATH}/v1/addconfigureteamsequence`, postData)
          //   .then(function (response) {
          //     if (response) {
          //       // console.log(response);
          //     }
          //   })
          //   .catch(function (error) {
          //     if (error.response) {
          //       console.log(error.response);
          //     }
          //   });
        }
      }
    }
  };

  /////////////// SAVING COLUMN VISIBILITY IN BACKEND /////////////////////////////////////////////////

  let storedVisibilty = props.column_visibilty;
  const [visibility, setVisibilty] = useState({});
  const [updatedVisiblty, setUpdatedVisibilty] = useState({});

  const [_flag, _setFlag] = useState(0);

  useEffect(() => {
    if (props.serverSideVisibilty) {
      if (_flag < 2) {
        _setFlag((prev) => {
          return prev + 1;
        });
        return;
      } else {
        const updatedVisiblty = storedVisibilty;

        for (const key in visibility) {
          if (visibility.hasOwnProperty(key)) {
            if (updatedVisiblty.hasOwnProperty(key)) {
              updatedVisiblty[key] = visibility[key];
            }
          }
        }

        const filteredData = {};

        for (const key in updatedVisiblty) {
          if (
            updatedVisiblty.hasOwnProperty(key) &&
            updatedVisiblty[key] === false
          ) {
            filteredData[key] = false;
          }
        }

        setUpdatedVisibilty(filteredData);

        const transformedArray = [];

        for (const key in updatedVisiblty) {
          transformedArray.push({
            name: key,
            display: updatedVisiblty[key].toString(),
          });
        }

        let postData = {
          swalhidden: true,
          tableName: props.persistTable,
          viewableColumn: transformedArray,
          columnOrder: "false",
        };

        // axios
        //   .post(`${API_PATH}/v1/addconfigureteamsequence`, postData)
        //   .then(function (response) {
        //     if (response) {
        //       console.log(response);
        //     }
        //   })
        //   .catch(function (error) {
        //     if (error.response) {
        //       console.log(error.response);
        //     }
        //   });
      }
    }
  }, [visibility]);

  const tooltipInnerStyle = {
    fontSize: "1rem",
    fontFamily: "Inter",
    color: "black",
    backgroundColor: "#fff",
    padding: "15px",
    // width : "500px"
    // border : "2px solid #3F50C2"
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnOrdering: true,
    enableColumnDragging: true,
    enableColumnPinning: true,

    enableGlobalFilter: true,

    enableFacetedValues: true,

    // enableColumnFilterModes: true,

    filterFns: {
      customFilterFn: (row, id, filterValue) => {
        return row.getValue(id) === filterValue;
      },
    },
    localization: {
      filterCustomFilterFn: "contains",
    },

    filterFns: {
      myCustomFilterFn: (row, id, filterValue) => {
        const rowValue = row.getValue(id)?.toString()?.toLowerCase();
        const lowerCaseFilterValue = filterValue.toLowerCase();
        return rowValue?.includes(lowerCaseFilterValue);
      },
    },
    globalFilterFn: "myCustomFilterFn",

    enableGrouping: true,
    groupedColumnMode: "remove",

    // rowPinningDisplayMode: 'select-sticky',
    // enableStickyHeader: true,

    autoResetPageIndex: false,

    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 25, 50, 100],
    },

    renderToolbarInternalActions: ({ table }) => (
      <>
        {props.hideToolbar && props.hideToolbar.includes("globalfilter") ? (
          <></>
        ) : (
          <MRT_ToggleGlobalFilterButton table={table} />
        )}

        {props.hideToolbar && props.hideToolbar.includes("showhidecolumns") ? (
          <></>
        ) : (
          <MRT_ShowHideColumnsButton table={table} />
        )}

        {props.hideToolbar && props.hideToolbar.includes("multifilter") ? (
          <></>
        ) : (
          <MRT_ToggleFiltersButton table={table} />
        )}

        {props.hideToolbar && props.hideToolbar.includes("density") ? (
          <></>
        ) : (
          <MRT_ToggleDensePaddingButton table={table} />
        )}

        {props.hideToolbar && props.hideToolbar.includes("fullscreen") ? (
          <></>
        ) : (
          <MRT_ToggleFullScreenButton table={table} />
        )}

        {props.hideToolbar && props.hideToolbar.includes("export") ? (
          <></>
        ) : (
          <div className="hover-circle">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Download
                color="#757575"
                style={{ margin: "8px", position: "relative", left: "1px" }}
                size={22}
              />
            </Dropdown>
          </div>
        )}

        {props.hideToolbar && props.hideToolbar.includes("reset") ? (
          <></>
        ) : (
          <div className="hover-circle">
            <RotateLeftIcon
              onClick={handleReset}
              style={{
                color: "#757575",
                margin: "8px",
                position: "relative",
                left: "1px",
              }}
            />
          </div>
        )}
      </>
    ),

    initialState: {
      showGlobalFilter: true,
    },

    state: {
      columnOrder: props.serverSideOrdering
        ? newColOrder[0]
          ? newColOrder
          : storedColumnOrder
        : columnOrder,
      columnVisibility: props.serverSideVisibilty
        ? Object.keys(updatedVisiblty).length === 0
          ? storedVisibilty
          : updatedVisiblty
        : visibility,
      columnFilters: filters,
      sorting: sorting,
      globalFilter: globalFilter,
      pagination: paginationRef.current,
      density: density,
      rowSelection: rowSelection,
      columnPinning: columnPinning,
    },

    onColumnOrderChange: props.serverSideOrdering
      ? (newOrder) => handleUpdateSeq(newOrder)
      : setColumnOrder,
    onColumnVisibilityChange: setVisibilty,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onDensityChange: setDensity,
    onRowSelectionChange: setRowSelection,
    onColumnPinningChange: setColumnPinning,
    ...options,
  });

  ////////////  LOCAL STORAGE FUNCTIONS FOR PERSISTING DATA IN ALLOCATION PAGES   ////////////////////////////////////////

  useEffect(() => {
    if (props.persistTable) {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }

      const currentState = {
        columnFilters: filters,
        columnOrder: !props.serverSideOrdering && columnOrder,
        visibility: !props.serverSideVisibilty && visibility,
        sorting: sorting,
        globalFilter: globalFilter,
        pagination: paginationRef.current,
        density: density,
        columnPinning: columnPinning,
        pageIndex: paginationRef.current?.pageIndex,
        pageSize: paginationRef.current?.pageSize,
      };

      // console.log("Going in local storage");

      localStorage.setItem(
        `${props.persistTable}`,
        JSON.stringify(currentState)
      );
    }
  }, [
    columnOrder,
    filters,
    sorting,
    globalFilter,
    density,
    paginationRef.current,
    visibility,
    columnPinning,
  ]);

  useEffect(() => {
    if (props.persistTable) {
      if (isMounted.current) {
        const savedState = JSON.parse(
          localStorage.getItem(`${props.persistTable}`)
        );

        if (savedState) {
          setpageIndex(savedState.pageIndex);
          setColumnOrder(!props.serverSideOrdering && savedState.columnOrder);
          setVisibilty(!props.serverSideVisibilty && savedState.visibility);
          setpageSize(savedState.pageSize);
          setFilters(savedState.columnFilters || []);
          setSorting(savedState.sorting || []);
          setGlobalFilter(savedState.globalFilter || "");
          setDensity(savedState.density || "");
          setColumnPinning(savedState.columnPinning || {});

          // console.log("Going in local storage");
        }
      }
      return () => {
        isMounted.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageIndex > 0) {
      var obj = {};
      obj["pageIndex"] = pageIndex;
      obj["pageSize"] = pageSize;
      setPagination(obj);
    }
    if (pageSize > 10) {
      var obj = {};
      obj["pageIndex"] = pageIndex;
      obj["pageSize"] = pageSize;
      setPagination(obj);
    }
  }, [pageIndex, pageSize]);

  const handleReset = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setpageIndex(0);
    setpageSize(10);
    setFilters([]);
    setSorting([]);
    setGlobalFilter("");
    setDensity("compact");
    setVisibilty({});
    setColumnOrder([]);
    setRowSelection({});
    setColumnPinning({});
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {false ? (
        <Skeleton count={15} />
      ) : (
        <>
          <div>
            {props.title && <h6 className="mb-3 mx-1">{props.title}</h6>}
            <MaterialReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
}

