import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  Switch,
} from "@mui/material";
import { FaTrash } from "react-icons/fa";
import axios from "axios";

export function ListedJobs({ updatedJob }) {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get("http://localhost:5000/api/job/get")
      .then((response) => setJobs(response.data))
      .catch((error) => console.error("Error fetching jobs:", error));
  }, [updatedJob]);

  const handleRemoveJob = (id) => {
    axios
      .delete(`http://localhost:5000/api/job/deleteById/${id}`)
      .then(() => {
        setJobs(jobs.filter((job) => job._id !== id));
      })
      .catch((error) => console.error("Error removing job:", error));
  };

  const handleViewDescription = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedJob(null);
    setIsModalOpen(false);
  };

  const toggleJobStatus = (id, currentStatus) => {
    axios
      .put(`http://localhost:5000/api/job/updateById/${id}`, {
        showJob: !currentStatus,
      })
      .then(() => {
        setJobs(
          jobs.map((job) =>
            job._id === id ? { ...job, showJob: !currentStatus } : job
          )
        );
      })
      .catch((error) => console.error("Error toggling job status:", error));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="jobs table">
          <TableHead>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Enable/Disable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job._id}>
                <TableCell>{job.title}</TableCell>
                <TableCell>{job.location}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleViewDescription(job)}
                  >
                    View
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<FaTrash />}
                    onClick={() => handleRemoveJob(job._id)}
                  >
                    Remove
                  </Button>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={job.showJob}
                    onChange={() => toggleJobStatus(job._id, job.showJob)}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Job Description */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="job-description-title"
        aria-describedby="job-description-content"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography id="job-description-title" variant="h6" component="h2">
            {selectedJob?.title}
          </Typography>
          <div
            id="job-description-content"
            dangerouslySetInnerHTML={{ __html: selectedJob?.description }}
          />
          <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}
