import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";

const initialJobs = [
  { id: 1, title: "Software Engineer", department: "Engineering" },
  { id: 2, title: "Product Manager", department: "Product" },
  { id: 3, title: "UX Designer", department: "Design" },
];

const initialCandidates = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    phone: "123-456-7890",
    email: "john@example.com",
    resumeFile: "john_doe_resume.pdf",
    noticePeriod: "30 days",
    appliedFor: "Software Engineer",
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Smith",
    phone: "098-765-4321",
    email: "jane@example.com",
    resumeFile: "jane_smith_resume.pdf",
    noticePeriod: "15 days",
    appliedFor: "Product Manager",
  },
  {
    id: 3,
    firstName: "Bob",
    lastName: "Johnson",
    phone: "555-555-5555",
    email: "bob@example.com",
    resumeFile: "bob_johnson_resume.pdf",
    noticePeriod: "60 days",
    appliedFor: "UX Designer",
  },
  {
    id: 4,
    firstName: "Alice",
    lastName: "Williams",
    phone: "444-444-4444",
    email: "alice@example.com",
    resumeFile: "alice_williams_resume.pdf",
    noticePeriod: "45 days",
    appliedFor: "Software Engineer",
  },
];

export default function EnhancedAdminDashboard() {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard Overview
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Jobs Listed
              </Typography>
              <Typography variant="h5" component="div">
                {initialJobs.length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Applicants
              </Typography>
              <Typography variant="h5" component="div">
                {initialCandidates.length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Notice Period
              </Typography>
              <Typography variant="h5" component="div">
                {Math.round(
                  initialCandidates.reduce(
                    (acc, candidate) => acc + parseInt(candidate.noticePeriod),
                    0
                  ) / initialCandidates.length
                )}{" "}
                days
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
