import React, { useState } from 'react'
import { 
  Button, 
  TextField, 
  Card, 
  CardContent, 
  CardActions, 
  Typography, 
  Alert, 
  Box 
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function LoginPage() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    setError('')

    if (!username || !password) {
      setError('Please fill in all fields')
      return
    }

    if (username === 'admin' && password === '1234') {
      navigate('/admin-pannel/dashboard')
    } else {
      setError('Invalid username or password')
    }
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default'
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', boxShadow: 'none' }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Login
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Enter your credentials to access your account
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <CardActions sx={{ justifyContent: 'flex-end', mt: 2 }}>
              <Button type="submit" variant="contained" fullWidth>
                Log in
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Box>
  )
}
