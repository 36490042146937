import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import { ListedJobs } from "./ListedJobs";
import { CandidateApplications } from "./CandidateApplications";
import { AddJobModal } from "./PostNewJob";

export default function EnhancedAdminDashboard() {
  const [openJobDialog, setOpenJobDialog] = useState(false);

  const [updatedJob, setUpdatedJobs] = useState(0)



  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 3 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Listed Jobs
      </Typography>

      <Button
        variant="contained"
        startIcon={<FaPlus />}
        onClick={() => setOpenJobDialog(true)}
        sx={{ mb: 2 }}
      >
        Post New Job
      </Button>

      <ListedJobs updatedJob={updatedJob} />

      <CandidateApplications />

      <AddJobModal
        open={openJobDialog}
        onClose={() => setOpenJobDialog(false)}
        setUpdatedJobs={setUpdatedJobs}
      />
    </Box>
  );
}
